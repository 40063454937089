<template>
    <modal ref="modalProducto" titulo="Productos" :tamano="producto.kit ? 'modal-xl' : 'modal-lg'" no-cancelar no-adicional>
        <div class="row mx-0 pt-4 pl-4 py-5">
            <div class="col-5 px-0">
                <vueper-slides class="no-shadow text-muted2 h-vueper3" :arrows-outside="false" :visible-slides="1" :touchable="false" autoplay :arrows="true">
                    <vueper-slide
                    v-for="(slide, idx) in producto.imagenes"
                    :key="idx"
                    >
                        <template v-slot:content>
                            <div class="row mx-0 j-center">
                                <img :src="slide ? slide : '/img/no-imagenes/mercado.svg'" class="obj-cover border br-12 img-product" width="266" height="266" />
                            </div>
                        </template>
                    </vueper-slide>
                    <template #arrow-left>
                        <div class="circled-arrow d-middle-center text-general border rounded-circle" style="width:24px;height:24px;">
                            <i class="icon-left-open f-12" />
                        </div>
                    </template>
                    <template #arrow-right>
                        <div class="circled-arrow d-middle-center text-general border rounded-circle" style="width:24px;height:24px;">
                            <i class="icon-right-open f-12" />
                        </div>
                    </template>
                </vueper-slides>
            </div>
            <div class="col-xl col-lg col-md col-sm-12 col-12 ml-4">
                <p class="f-20 text-general f-500 nombre-producto">
                    {{ producto.nombre }}
                </p>
                <p class="my-2 f-300">
                    <span class="f-400">Presentación:</span> {{ producto.presentacion }}
                </p>
                <div class="row mx-0">
                    <p class="col-auto f-600 px-0 text-general f-24 px-0">
                        {{ producto.promocion ? formatNumero(producto.data_promocion.promo_valor, 0, true) : formatNumero(producto.precio, 0, true) }}
                    </p>
                    <p v-show="producto.promocion" class="col-auto f-600 ml-3 text-general2 f-24 px-0 descuento">
                        {{ formatNumero(producto.precio, 0, true) }}
                    </p>
                    <div class="bg-white circled-heart ml-auto d-flex rounded-circle a-center j-center" @click="agregarFavorito">
                        <i :class="`${favorito ? 'icon-heart text-red' : 'icon-heart-outline text-gris2'} cr-pointer`" />
                    </div>
                </div>
                <div v-if="producto.promocion" class="row mx-0 mt-3 mb-4">
                    <div class="bg-general px-3 f-11 br-20 py-1 text-white f-500">
                        {{ producto.data_promocion.texto }}
                    </div>
                </div>
                <p class="text-gris2 f-15 my-4">
                    {{ producto.descripcion }}
                </p>
                <div class="row mx-0 j-center">
                    <div v-show="cant > 0" class="col-4">
                        <div class="row">
                            {{ formatNumero(totalFinal, 0, true) }}
                        </div>
                        <div class="row">
                            <b>Valor Total</b> 
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="btn-general br-12" style="height:44px;">
                            <i v-if="cant == 1" class="icon-trash-outline f-17 text-white ml-2" @click="cant = 0" />
                            <i v-if="cant > 1" class="icon-minus f-17 text-white ml-2" @click="cant = cant - 1" />
                            <span v-if="cant >= 1" class="w-100 h-100 d-middle-center f-22" @click="cant = cant + 1">{{ cant }}</span>
                            <i v-if="cant >= 1" class="icon-plus f-20 text-white mr-2" @click="cant = cant + 1" />
                            <span v-if="cant == 0" class="w-100 h-100 d-middle-center" @click="cant = cant + 1">Añadir</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="producto.kit" class="col-xl col-lg col-md col-sm-12 col-12">
                <productos-kits ref="detalleKit" />
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            existeEnCarrito: false,
            producto: {},
            cant: 0,
            slides: [
                {text: ''},
                {text: ''},
                {text: ''},
            ],
            productosKit: false,
            favorito: false
        }
    },

    computed: {
        ...mapGetters({
            carrito: 'invitado/carrito',
            favoritos: 'invitado/favoritos',
        }),
        totalTeorico(){
            if(this.cant == 0){
                return this.producto.precio
            }
            return this.producto.precio * this.cant
        },
        totalFinal(){
            if(this.cant == 0 && this.producto.promocion){
                return this.producto.data_promocion.promo_valor
            }

            if(this.cant == 0 && !this.producto.promocion){
                return this.producto.precio
            }

            if(this.producto.promocion){
                return this.producto.data_promocion.promo_valor * this.cant
            }else{
                return this.producto.precio * this.cant
            }
        }
    },
    
    watch: {
        cant(){
            this.changeCantidad();
        }
    },
    methods:{
        toggle(producto){
            this.existeEnCarrito = false;
            this.producto = producto;
            this.esFavorito();
            this.actualizarCantidad();
            if(this.producto.kit){
                this.getKit()
            }
            this.$refs.modalProducto.toggle();
        },
        async getKit(){
            await this.$refs.detalleKit.getProductosKit(this.producto.id_producto)
        },

        esFavorito(){
            setTimeout(() => {
                let esFavorito = this.favoritos.find((item) => item.id_producto === this.producto.id_producto);
                if(esFavorito){
                    this.favorito = true; 
                }
            },100);
        },

        actualizarCantidad(){
            let data = this.carrito.find((item) => item.id_producto === this.producto.id_producto);
            if(data){
                this.existeEnCarrito = true;
                this.cant = data.cantidad;
            }
        },
        changeCantidad(){
            if(this.existeEnCarrito){
                
                let index = this.carrito.findIndex((item) => item.id_producto === this.producto.id_producto);
                if(this.cant < 1){
                    return this.removerProductoCarrito(index);
                }
                return this.actualizarProductoCarrito(index);
            } else {
                return this.agregarProductoCarrito();
            }
        },
        agregarProductoCarrito(){
            let dataProducto = {};
            dataProducto.id_producto = this.producto.id_producto;
            dataProducto.cantidad = this.cant;
            // Actualizamos el carrito en vuex
            this.carrito.push(dataProducto);
            const asignar = JSON.stringify(this.carrito);
            // Actualizamos el carrito en cache del navegador
            localStorage.setItem('carritoInvitado', asignar);
            this.existeEnCarrito = true;
            this.$emit('change');
        },

        actualizarProductoCarrito(index){
            this.carrito[index].cantidad = this.cant;
            const asignar = JSON.stringify(this.carrito);
            localStorage.setItem('carritoInvitado', asignar);
            this.$emit('change');
            return;
        },

        removerProductoCarrito(index){
            this.carrito.splice(index,1);
            const asignar = JSON.stringify(this.carrito);
            localStorage.setItem('carritoInvitado', asignar);
            let esVacio = JSON.parse(localStorage.getItem('carritoInvitado'));
            if(esVacio.length < 1){
                localStorage.removeItem('carritoInvitado');
            }
            this.$emit('change');
            this.existeEnCarrito = false;
        },

        agregarFavorito(){
            this.favorito = !this.favorito;
            if(this.favorito){
                let data = { id_producto: this.producto.id_producto };
                this.favoritos.push(data);
                const asignar = JSON.stringify(this.favoritos);
                localStorage.setItem('favoritosInvitado', asignar);
                this.$emit('changeFavorito', 'agrego');
            }else{
                let index = this.favoritos.findIndex((item) => item.id_producto === this.producto.id_producto);
                this.favoritos.splice(index, 1);
                const asignar = JSON.stringify(this.favoritos);
                localStorage.setItem('favoritosInvitado', asignar);
                this.$emit('changeFavorito', 'elimino');
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.pill{
    min-width: 24px;
    height: 24px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    box-shadow: 0px 2px 6px #0000000D;
    background-color: #000000;
    border-radius: 16px;
}
.circled-heart{
    width:24px;
    height:24px;
    top:10px;
    right:16px;
    box-shadow: 0px 2px 6px #0000000D;
}
</style>